<template>
    <div class="text-center">
        <h1 class="text-danger mb-4">Accès non autorisé</h1>

        <div>
            <b-button class="m-2" variant="kalkin-1" @click="goHome">
                <b-icon-house-door-fill /> Retour à l'accueil</b-button
            >
            <b-button class="m-2" variant="outline-kalkin-1" @click="goHome">
                <b-icon-door-open-fill /> Se déconnecter</b-button
            >
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goHome: function() {
            this.$router.push({ name: "Home" });
        },
        logout: function() {
            this.$store.dispatch("user/logout");
            this.$router.push({ name: "Login" });
        },
    },
};
</script>

<style></style>
